import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  images?: ImageProps[]
  title?: string
}

export const Item = memo(function Item({ description, images, title }: Props) {
  return (
    <Container row stretch wrap>
      <LeftSide dial={4}>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </LeftSide>

      <RightSide row>
        {images
          ? images.slice(0, 2).map((item, index) => (
              <LazyLoadComponent key={index}>
                <Image {...item} />
              </LazyLoadComponent>
            ))
          : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 1.875rem;

  &:first-of-type {
    margin-top: 0;
  }

  &:nth-of-type(odd) {
    > div {
      &:first-of-type {
        order: 2;
        padding-right: 8.958vw;
        padding-left: 9.236vw;
        margin-left: 1.875rem;
        margin-right: 0;
      }
    }
  }

  @media (max-width: 1199px) {
    &:nth-of-type(odd) {
      > div {
        &:first-of-type {
          padding-right: 1.875rem;
          padding-left: 1.875rem;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    &:nth-of-type(odd) {
      > div:first-of-type {
        margin-left: 0;
      }
    }

    &:nth-of-type(even) {
      > div {
        &:last-of-type {
          picture {
            margin-right: 0;
          }
        }
      }
    }
  }
`

const LeftSide = styled(FlexBox)`
  width: calc(50% - 0.9375rem);
  padding: 7.5rem 9.236vw 7.5rem 10.278vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-right: 1.875rem;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.875rem;
  }

  @media (max-width: 1023px) {
    margin-right: 0;
    width: 100%;
    order: 2;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-size: 1.875rem;
  line-height: 2.8125rem;
  position: relative;
  margin: 0 0 2.5rem;

  &:after {
    content: '';
    display: block;
    width: 3rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight2};
    margin: 0 auto;
    position: absolute;
    top: 50%;
    margin-top: -0.0625rem;
    right: -5.5rem;
    transform: translateX(-50%);
  }

  @media (max-width: 1199px) {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }

  @media (max-width: 1023px) {
    margin-bottom: 1rem;

    &:after {
      display: none;
    }
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 2rem;

  @media (max-width: 1199px) {
    font-size: 1.0625rem;
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
  }
`

const RightSide = styled(FlexBox)`
  width: calc(50% - 0.9375rem);
  overflow: hidden;
  position: relative;

  picture {
    width: 50%;
    position: relative;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:first-of-type {
      margin-right: 1.875rem;
    }
  }

  @media (max-width: 1023px) {
    width: 100%;
    justify-content: space-between;

    picture {
      width: calc(50% - 0.375rem);
      min-height: 28vh;
      margin-left: 0;

      &:first-of-type {
        margin-right: 0;
      }
    }
  }
`
