import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  title?: string
}

export const ZigZag = memo(function ZigZag({ items, title }: Props) {
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <Line className="line" variant="compact" />
        </FadeInUp>
      ) : null}
      {items.map((item, index) => (
        <Item key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section`
  margin: 16.25rem 0 12.5rem;

  .line {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;
  }

  @media (max-width: 1199px) {
    margin: 7.5rem 0;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-size: 3.125rem;
  line-height: 3.875rem;
  font-weight: 500;
  position: relative;
  margin: 0 auto;
  text-align: center;
  max-width: 26rem;

  b {
    font-style: italic;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.variants.primaryLight2};
  }

  @media (max-width: 1199px) {
    max-width: auto;
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`
